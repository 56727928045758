import logo from './graip.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>GrAIP KPI stats</h1>
        <p>(<i>stats and numbers as of <b>June 24th, 2024</b></i>)</p>
        <p>Live products running on the GrAIP: <b>4</b>
          <ul>
            <li>LegalAI</li>
            <li>DOR</li>
            <li>LlmaaS</li>
            <li>Beetle Bot</li>
          </ul>
        </p>
        <p>
          Amount of users registered on the GrAIP: <b>151</b>
        </p>
      </header>
    </div>
  );
}

export default App;
